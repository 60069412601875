import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quote from "../../img/quote-1.png";
import umb from "../../img/umb.png";
import umblight from "../../img/umb-light.svg"
import wsfs from "../../img/wsfsinstitutionalservices.png";
import { Box } from "@mui/material";

const ClientTestimonials = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );

  // working
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    window.addEventListener("storage", checkTheme);

    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Default: Show 1 slide at a time
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024, // For screens <= 1024px
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // For screens <= 768px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time for small screens
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480, // For screens <= 480px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time for extra small screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false, // Keep arrows visible on larger screens
        }
      }
    ]
  };
  
  return (
    <Box className="client">
      <div className="platform-wh">
        <div>Client Testimonials</div>
        <span></span>
      </div>
      <Box className="testimonial-box">
        <div className="carousel-container">
          <Slider {...settings}>
            {/* Slide 1 */}
            <div className="carousel-slide">
              <img src={quote} alt="quote" />
              <blockquote className="quote">
                “We are thrilled to provide our clients with the newest
                technology available so they can focus on serving their markets.
                Most technological innovation in the industry to-date has been
                focused on front-end loan originations. Our work with Intain
                will allow us to produce trustee reports faster, with more
                accuracy and access to loan level detail to investors.”
              </blockquote>
              <p className="author">
                <span>Kristin Moore,</span> Senior Vice President, Director
                <br />
                Corporate Trust, WSFS Institutional Services
              </p>

              <img
                src={wsfs}
                alt="wsfs"
                className="wsfs-carousel"
                
              
              />
            </div>

            {/* Slide 2 */}
            <div className="carousel-slide">
              <img src={quote} alt="quote" />
              <blockquote className="quote">
                "Our relationship has grown from utilizing Intain services as
                our calculation agent to now providing industry-leading Al
                services for our verification and custody teams. We are excited
                about what the future holds."
              </blockquote>
              <p className="author">Stuart Mitchel, SVP, UMB Bank</p>
              <img
                src={isDarkMode ? umb : umblight}
                alt="umb"
                className="umb-carousel"
                // style={{ width: "374px", height: "100px", marginTop: "7rem" }}
              />
            </div>
          </Slider>
        </div>
      </Box>
    </Box>
  );
};

export default ClientTestimonials;