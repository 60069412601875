import axios from "axios";
import { PATH, URL } from "../Constants/index";
import toast from "react-hot-toast";
export const ContactApi = async (data) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.contact}`,
    data,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      return toast.success(api.data.message);
    } else {
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};
export const Intern = async (data) => {
  console.log([...data], "dddd");

  const args = {
    method: "POST",
    url: `${URL}${PATH.intern}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    let api = await axios(args); // Only keep this one
    if (api.status === 200) {
      console.log("Data", api.data);
      return toast.success(api.data.message);
    } else {
      console.log("No Data");
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};

export const signin = async (data) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.login}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    const api = await axios(args);

    if (api.status === 200) {
      toast.success(api.data.message);
      return true; // Ensure success returns true
    }
  } catch (error) {
    console.error(error, "error");

    if (error.response) {
      const errorMessage =
        error.response.data?.message || "An unexpected error occurred";
      toast.error(errorMessage);
    } else {
      toast.error("Network error or server not reachable.");
    }
    return false; // Ensure failure returns false
  }
};


export const emailSubscription = async (data) => {
  console.log(data, "data");
  const data1 = {
    mail: data,
  };
  console.log(data1, "data");

  const args = {
    method: "POST",
    url: `${URL}${PATH.emailSubscribe}`,
    data: data1,
  };

  try {
    const api = await axios(args); // Make the call only once
    if (api.status === 200) {
      console.log("Data", api.data);
      // Assuming the API returns a message in `api.data.message`
      return toast.success(api.data.message);
      // { success: true, message: api.data.message };
    } else {
      console.log("No Data");
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error.response?.data.message, "error");
    if (error && error.response) {
      return toast.error(error.response?.data.message);
    }
  }
};

export const register = async (data) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.register}`,
    data,
  };

  try {
    const api = await axios(args);
    if (api.status === 200) {
      return toast.success(api.data.message);
    } else {
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};

export const productsubscribe = async (data) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.productsubscribe}`,
    data,
  };

  try {
    const api = await axios(args);
    if (api.status === 200) {
      return toast.success(api.data.message);
    } else {
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};

export const mediumpost = async (post) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.medium}`,
    data: { slide: post },
  };

  try {
    const api = await axios(args);

    if (api.status === 200) {
      console.log(api, "api");
      return api.data;
    } else {
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};


export const AddNews = async (data) => {
  const args = {
    method: "POST",
    url: `${URL}${PATH.addnews}`,
    data,
  };

  try {
    let api = await axios(args);
    console.log(api,'appp')
    if (api.status === 200) {
      return toast.success(api.data.message);
    } else {
      return toast.error(api.data.message);
    }
  } catch (error) {
    console.error(error, "error");
    if (error && error.response) {
      return toast.error(error.response?.data);
    }
  }
};