import React, { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/Sidebar";
import NewsTable from "../../components/Table/Table";
import NewsFormDialog from "../../components/NewsForm/NewsForm";
import { useLocation } from "react-router-dom"; // Import useLocation
import { AddNews } from "../../Actions/Action";

const NewsManagement = () => {
  const location = useLocation(); // Get the current location object
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    content: "",
    date: "",
    image: null,
  });
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      content: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  const handleSubmit = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);  // Change "Title" to "title"
      formDataToSend.append("content", formData.content);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("date", formData.date);
      
      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }
  
      // Call AddNews function
      const response = await AddNews(formDataToSend);
  
      if (response) {
        // Assuming AddNews shows a success toast and you want to update the state
        setNewsList((prevList) => [
          ...prevList,
          { ...formData, id: Date.now() },
        ]);
  
        // Reset form
        setOpenForm(false);
        setFormData({
          title: "",
          description: "",
          content: "",
          date: "",
          image: null,
        });
        setSelectedNews(null);
      }
    } catch (error) {
      console.error("Error adding news:", error);
    }
  };
  

  const handleDelete = (id) => {
    setNewsList((prevList) => prevList.filter((news) => news.id !== id));
  };

  const handleAddNews = () => {
    setFormData({
      title: "",
      description: "",
      content: "",
      date: "",
      image: null,
    });
    setSelectedNews(null);
    setOpenForm(true);
  };

  const handleEdit = (news) => {
    setSelectedNews(news);
    setFormData(news);
    setOpenForm(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        style={{
          marginLeft: isCollapsed ? "60px" : "40px",
          transition: "margin-left 0.3s",
          padding: "16px",
          width: "100%",
        }}
      >
        <Typography variant="h4" className="typography-color" gutterBottom>
          News Management
        </Typography>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Button
            variant="contained"
            color="primary"
            className="add-btn"
            onClick={handleAddNews}
          >
            Add News
          </Button>
        </Box>
        <NewsTable
          newsList={newsList}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <NewsFormDialog
          open={openForm}
          onClose={() => setOpenForm(false)}
          formData={formData}
          onChange={handleChange}
          onContentChange={handleContentChange}
          onImageChange={handleImageChange}
          onSubmit={handleSubmit}
          isEditing={!!selectedNews}
        />
      </div>
    </div>
  );
};

export default NewsManagement;
