import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Ensure the styles are included
import "./NewsForm.css"
const NewsFormDialog = ({
  open,
  onClose,
  formData,
  onChange,
  onContentChange,
  onImageChange,
  onSubmit,
  isEditing,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="custom-dialog">
      <DialogTitle>{isEditing ? "Edit News" : "Add News"}</DialogTitle>
      <DialogContent>
        {/* Title */}
        <TextField
          label="Title"
          fullWidth
          name="title"
          value={formData.title}
          onChange={onChange}
          margin="normal"
         
        
        />

        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          name="description"
          value={formData.description}
          onChange={onChange}
          margin="normal"
        />

        {/* Content */}
        <Box my={2}>
          <ReactQuill
            value={formData.content}
            onChange={onContentChange}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link"],
                ["image"],
                [{ align: [] }],
                ["clean"],
              ],
            }}
            className="quill-editor" 
          />
        </Box>

        {/* Date */}
        <TextField
          label="Date"
          fullWidth
          type="date"
          name="date"
          value={formData.date}
          onChange={onChange}
          margin="normal"
        />

        {/* Image Upload */}
        <Box mt={2}>
          <input
            type="file"
            accept="image/*"
            onChange={onImageChange}
            style={{ display: "none" }}
            id="upload-image"
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span" fullWidth>
              {formData.image ? "Change Image" : "Upload Image"}
            </Button>
          </label>
          {formData.image && (
            <Box mt={1}>
              <img
              src={URL.createObjectURL(formData.image)} // Preview the image
              alt="Preview"
              width={100}
              height={100}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)} // Cleanup URL
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className="typography-color" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          {isEditing ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsFormDialog;
