import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import {
  Home,
  Article,
  Event,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const drawerWidth = isCollapsed ? 60 : 240;

  // Navigation items
  const menuItems = [
    { text: "Dashboard", icon: <Home />, path: "/admin/dashboard" },
    { text: "News Management", icon: <Article />, path: "/admin/news" },
    { text: "Events Management", icon: <Event />, path: "/admin/events" },
  ];

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          transition: "width 0.3s",
          backgroundColor: "var(--sidebar)",
          overflow: "hidden",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        {isCollapsed ? (
          <IconButton onClick={toggleSidebar}>
            <ChevronRight   className="typography-color"/>
          </IconButton>
        ) : (
          <>
            <label className="typography-color">Admin Panel</label>
            <IconButton onClick={toggleSidebar}>
              <ChevronLeft className="typography-color" />
            </IconButton>
          </>
        )}
      </Typography>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
            sx={{
              backgroundColor: isActive(item.path) ? "#1a534e" : "transparent",
              "&:hover": {
                backgroundColor: "#1a534e",
                borderRadius: "15px",
              },
              borderRadius: isActive(item.path) ? "15px" : "",
            }}
          >
            <ListItemIcon
              sx={{ color: isActive(item.path) ? "white" : "#185955" }}
            >
              {item.icon}
            </ListItemIcon>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
              <ListItemText primary={item.text} className="typography-color" />
            </Collapse>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideBar;

// import { useEffect, useState } from "react";
// import axios from "axios";

// const Dashboard = () => {
//   const [stats, setStats] = useState({ users: 0, news: 0, events: 0 });

//   useEffect(() => {
//     axios.get("/api/admin/stats")
//       .then(response => setStats(response.data))
//       .catch(error => console.log(error));
//   }, []);

//   return (
//     <div>
//       <Typography variant="h4" sx={{ marginBottom: 2 }}>
//         Admin Dashboard
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Total Users Card */}
//         <Grid item xs={12} sm={6} md={3}>
//           <Card>
//             <CardContent>
//               <Box display="flex" alignItems="center">
//                 <People sx={{ fontSize: 40, color: "blue" }} />
//                 <Box ml={2}>
//                   <Typography variant="h6">Total Users</Typography>
//                   <Typography variant="h5">{stats.users}</Typography>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* Repeat for other cards */}
//       </Grid>
//     </div>
//   );
// };
