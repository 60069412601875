import React from "react";
import { Table, Button } from "antd";
import "./Table.css"

const NewsTable = ({ newsList, onEdit, onDelete }) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>, // You can customize rendering as needed
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, news) => (
        <span>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "8px" }}
            onClick={() => onEdit(news)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => onDelete(news.id)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={newsList}
      rowKey="id" // Make sure each row has a unique key (news.id)
      pagination={{ pageSize: 10 }} // Adjust the pagination if needed
    />
  );
};

export default NewsTable;
