// export const URL = "https://supportapi.in-d.ai/";
// export const URL = "http://localhost:5000/";
export const URL = "https://api.intainft.com/";

export const PATH = {
  contact: "user/contact",
  register: "user/register",
  intern: "user/intern",
  login: "user/login",
  emailSubscribe: "user/subscribe",
  productsubscribe: "user/productsubscribe",
  medium: "user/medium-posts",
  addnews:"user/news"
};
