import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton, Collapse, Box, Grid, Card, CardContent } from "@mui/material";
import { Home, Article, Event, People, Settings, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import Chart from "react-apexcharts"; // Assuming you are using a charting library

const SideBar = ({ isCollapsed, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const drawerWidth = isCollapsed ? 60 : 240;

  // Navigation items
  const menuItems = [
    { text: "Dashboard", icon: <Home />, path: "/admin/dashboard" },
    { text: "News Management", icon: <Article />, path: "/admin/news" },
    { text: "Events Management", icon: <Event />, path: "/admin/events" },
    // { text: "Users", icon: <People />, path: "/admin/users" },
    // { text: "Settings", icon: <Settings />, path: "/admin/settings" },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          transition: "width 0.3s",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        {isCollapsed ? (
          <IconButton onClick={toggleSidebar}>
            <ChevronRight />
          </IconButton>
        ) : (
          <>
            Admin Panel
            <IconButton onClick={toggleSidebar}>
              <ChevronLeft />
            </IconButton>
          </>
        )}
      </Typography>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
            sx={{
              backgroundColor: isActive(item.path) ? "#ddd" : "transparent",
              "&:hover": {
                backgroundColor: "#ccc",
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
              <ListItemText primary={item.text} />
            </Collapse>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  // Chart data (for example purposes)
  const chartData = {
    options: {
      chart: {
        id: "user-signups",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      },
    },
    series: [
      {
        name: "Signups",
        data: [30, 40, 35, 50, 49, 60],
      },
    ],
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        style={{
          marginLeft: isCollapsed ? "60px" : "40px",
          transition: "margin-left 0.3s",
          padding: "16px", // Optional padding
          width: "100%",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Admin Dashboard
        </Typography>

        {/* Grid Layout for Summary Cards */}
        <Grid container spacing={3}>
          {/* Total Users Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <People sx={{ fontSize: 40, color: "blue" }} />
                  <Box ml={2}>
                    <Typography variant="h6">Total Users</Typography>
                    <Typography variant="h5">1,234</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Total News Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Article sx={{ fontSize: 40, color: "green" }} />
                  <Box ml={2}>
                    <Typography variant="h6">Total News</Typography>
                    <Typography variant="h5">456</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Upcoming Events Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Event sx={{ fontSize: 40, color: "orange" }} />
                  <Box ml={2}>
                    <Typography variant="h6">Upcoming Events</Typography>
                    <Typography variant="h5">5</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* More Summary Cards as needed */}
        </Grid>

        {/* Chart Section */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5">User Signups Over Time</Typography>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
        </Box>

        {/* Recent Activities Section */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5">Recent Activities</Typography>
          <ul>
            <li>User John Doe added a new news article</li>
            <li>Event "Annual Meeting" scheduled for next week</li>
            <li>New user "Jane Smith" registered</li>
            {/* More activities */}
          </ul>
        </Box>
      </div>
    </div>
  );
};

export default Dashboard;
