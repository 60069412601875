import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/Sidebar";
import NewsTable from "../../components/Table/Table";
import NewsFormDialog from "../../components/NewsForm/NewsForm";

const EventsManagement = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    content: "",
    date: "",
    image: null,
  });
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      content: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file, // Ensure `image` is a File object
      }));
    }
  };
  
  const handleSubmit = () => {
    if (selectedNews) {
      setNewsList((prevList) =>
        prevList.map((news) =>
          news.id === selectedNews.id ? { ...formData, id: news.id } : news
        )
      );
     
    } else {
      setNewsList((prevList) => [...prevList, { ...formData, id: Date.now() }]);
      console.log(formData,'formm')
    }
    setOpenForm(false);
    setFormData({
      title: "",
      description: "",
      content: "",
      date: "",
      image: null,
    });
    setSelectedNews(null);
  };

  const handleDelete = (id) => {
    setNewsList((prevList) => prevList.filter((news) => news.id !== id));
  };

  const handleAddNews = () => {
    setFormData({
      title: "",
      description: "",
      content: "",
      date: "",
      image: null,
    });
    setSelectedNews(null);
    setOpenForm(true);
  };

  const handleEdit = (news) => {
    setSelectedNews(news);
    setFormData(news);
    setOpenForm(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        style={{
          marginLeft: isCollapsed ? "60px" : "40px",
          transition: "margin-left 0.3s",
          padding: "16px",
          width: "100%",
        }}
      >
        <Typography variant="h4" className="typography-color" gutterBottom>
          Events Management
        </Typography>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Button
            variant="contained"
            color="primary"
            className="add-btn"
            onClick={handleAddNews}
          >
            Add Events
          </Button>
        </Box>
        <NewsTable
          newsList={newsList}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <NewsFormDialog
          open={openForm}
          onClose={() => setOpenForm(false)}
          formData={formData}
          onChange={handleChange}
          onContentChange={handleContentChange}
          onImageChange={handleImageChange}
          onSubmit={handleSubmit}
          isEditing={!!selectedNews}
        />
      </div>
    </div>
  );
};
export default EventsManagement;
