import React, { useState, useEffect } from "react";
const LinkedIn = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    window.addEventListener("storage", checkTheme);

    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);
  return (
    <div className="linkediin">
      {/* <iframe
        title="LinkedIn"
        src=
        "https://widgets.sociablekit.com/linkedin-page-posts/iframe/130929"
        frameborder="0"
      ></iframe> */}
      <iframe
        src={
          !isDarkMode
            ? "https://widgets.sociablekit.com/linkedin-page-posts/iframe/25509233"
            : "https://widgets.sociablekit.com/linkedin-page-posts/iframe/25509252"
        }
        frameborder="0"
        width="100%"
        height="1000"
      ></iframe>{" "}
    </div>
  );
};

export default LinkedIn;

// explore dark
// <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25509233' frameborder='0' width='100%' height='1000'></iframe>

// explore light
// <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25509252' frameborder='0' width='100%' height='1000'></iframe>
