import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import news1 from "../../img/press.svg";
import news2 from "../../img/press2.svg";
import news3 from "../../img/press3.svg";
import news4 from "../../img/press4.svg";
import news5 from "../../img/press5.svg";
import news6 from "../../img/press6.svg";
import news7 from "../../img/press7.svg";
import news8 from "../../img/press8.svg";
import news9 from "../../img/press9.svg";
import news10 from "../../img/press10.svg";
import news11 from "../../img/press11.svg";
import news12 from "../../img/press12.svg";



const NewsandPRess = () => {

  const newsItems = [
    {
      navigateTo:
        "https://fortune.com/crypto/2023/02/02/alt-coins-polygon-avalanche-aptos-surge-stock-market-rallies-fed-news/amp/",
      image: news1,
      label:
        "Altcoins Polygon, Avalanche, and Aptos surge as stock market rallies on Fed news.",
      link: "www.fortune.com",
    },
    {
      navigateTo:
        "https://coindesk.com/business/2023/01/31/structured-finance-platform-intain-opens-tokenized-marketplace-for-asset-backed-securities/",

      image: news2,
      label:
        "Structured Finance Platform Intain Opens Tokenized Marketplace for Asset-Backed Securities.",
      link: "www.coindesk.com",
    },
    {
      navigateTo:
        "https://www.ledgerinsights.com/blockchain-securitization-intain-funding-jam-fintop-avalanche/",
      image: news3,
      label:
        "Intain backed by JAM FINTOP, Avalanche fund Blizzard for blockchain securitization",
      link: "www.ledgerinsights.com",
    },
    {
      navigateTo:
        "https://www.fintechnexus.com/intain-adds-ai-loan-validation-with-umb-bank-as-first-customer/",

      image: news4,
      label: "Intain adds AI loan validation with UMB Bank as first customer.",
      link: "news.fintechnexus.com",
    },
    {
      navigateTo:
        "https://globalfintechseries.com/artificial-intelligence/umb-financial-corporation-becomes-first-bank-to-deploy-intains-verification-agent/",
      image: news5,
      label:
        "UMB Financial Corporation Becomes First Bank to Deploy Intain’s Verification Agent.",
      link: "www.globalfintechseries.com",
    },
    {
      navigateTo:
        "https://bankautomationnews.com/allposts/business-banking/wsfs-taps-blockchain-firm-intain-to-automate-capital-markets-transactions/",
      image: news6,
      label:
        "WSFS taps blockchain firm Intain to automate capital markets transactions",
      link: "www.bankautomationnews.com",
    },
    {
      navigateTo:
        "https://www.fintechnexus.com/intains-boring-blockchain-plumbing-opens-door-to-mass-adoption/",
      image: news7,
      label:
        "Intain’s ‘boring blockchain’ plumbing opens door to mass adoption.",
      link: "news.fintechnexus.com",
    },
    {
      navigateTo:
        "https://www.thehindu.com/business/intain-fintech-to-launch-software-product-in-india/article26601090.ece",
      image: news8,
      label: "Intain Fintech to launch software product in India.",
      link: "www.thehindu.com",
    },
    {
      navigateTo:
        "https://www.prnewswire.com/news-releases/intain-appoints-eric-mitzel-as-north-america-head-of-sales-and-client-solutions-to-capitalize-on-growth-and-momentum-in-the-region-301441206.html",
      image: news9,
      label:
        "Intain Appoints Eric Mitzel as North America Head of Sales and Client Solutions to Capitalize on Growth and Momentum in the Region",
      link: "Press Release",
    },
    {
      navigateTo:
        "https://www.businesswireindia.com/intain-the-blockchain-company-managing-more-than-usd-4-billion-in-assets-on-its-platform-demonstrates-there-is-more-to-blockchain-than-crypto-and-nfts-77948.html",
      image: news10,
      label:
        "Intain, the Blockchain Company Managing More Than USD 4 Billion in Assets on Its Platform, Demonstrates There Is More to Blockchain Than Crypto and NFTs.",
      link: "Press Release",
    },
    {
      navigateTo:
        "https://www.prnewswire.com/news-releases/end-capital-and-intain-partner-for-digital-structured-finance-transactions-301588598.html",
      image: news11,
      label:
        "END Capital and Intain Partner for Digital Structured Finance Transactions",
      link: "Press Release",
    },
    {
      navigateTo:
        "https://www.prnewswire.com/news-releases/avalanche-foundations-blizzard-fund-and-jam-fintop-blockchain-back-intains-deployment-of-blockchain-in-traditional-financial-systems-301581423.html",
      image: news12,
      label:
        "Avalanche Foundation's Blizzard Fund and JAM FINTOP Blockchain Back Intain's Deployment of Blockchain in Traditional Financial Systems",
      link: "Press Release",
    },
  ];

  return (
    <div className="custom-managment-page news-grid-container">
      <div className="team-title platform" style={{marginTop:'4rem'}}>
        <h1>News and Press Releases</h1>
        <span></span>
      </div>

      <div className="news-grid">
        {newsItems.map((item, index) => (
          <div
            key={index}
            className="news-container"
            onClick={() => window.open(item.navigateTo, "_blank")}
          >
            <img src={item.image} alt="news" />
            <label>{item.label}</label>
            <p>{item.link}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const News = () => {
  return (
    <div>
      <Header />
      <NewsandPRess />
       <Footer /> 
    </div>
  );
};

export default News;
