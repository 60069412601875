import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import award from "../../img/rectangle-49.png";
import avalanche from "../../img/rectangle-53.png";
import SFAVegas from "../../img/rectangle-56.png";
import blockchain from "../../img/image.png";
import SFAVegas2023 from "../../img/SFA-Vegas-Web.png";
import SFABlockChain from "../../img/101-Blockchain-SFA-Vegas.png";
import ABS from "../../img/Vegas.png";
import whatsnew from "../../img/whatsnew.svg";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { Box } from "@mui/material";
// import Ida from "../../img/Ida.svg";
import Ida from "../../img/IDA_img.png";
import { mediumpost } from "../../Actions/Action";
import news1 from "../../img/press.svg";
import news2 from "../../img/press2.svg";
import Events1 from "../../img/Events.svg";
import Events2 from "../../img/Events2.svg";

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff", // Arrow color
        fontSize: "30px", // Arrow size
        cursor: "pointer",
        position: "absolute",
        left: "-40px",
        zIndex: 1,
        marginTop: "9.2rem",
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: "20px" }}>
        <ArrowBackIos />
      </span>{" "}
      {/* Change the arrow icon as needed */}
    </div>
  );
};

// Custom next arrow component
const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <div className="arrow" onClick={onClick}>
      <span style={{ fontSize: "20px" }}>
        <ArrowForwardIos />
      </span>{" "}
      {/* Change the arrow icon as needed */}
    </div>
  );
};

const WhatsNew = () => {
  const [post, setPost] = useState();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show one slide at a time
    // slidesToScroll: 1,
    autoplay: false,
    arrows: true, // Show navigation arrows
    prevArrow: <SamplePrevArrow />, // Custom prev arrow
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024, // For screens <= 1024px
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For screens <= 768px
        settings: {
          slidesToShow: 2, // Show 1 slide at a time for small screens
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480, // For screens <= 480px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time for extra small screens
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false, // Keep arrows visible on larger screens
        },
      },
    ],
  };

  const newsItems = [
    {
      navigateTo:
        "https://fortune.com/crypto/2023/02/02/alt-coins-polygon-avalanche-aptos-surge-stock-market-rallies-fed-news/amp/",
      image: news1,
      label:
        "Altcoins Polygon, Avalanche, and Aptos surge as stock market rallies on Fed news.",
      link: "www.fortune.com",
      date: "02/02/2023",
    },
    {
      navigateTo:
        "https://coindesk.com/business/2023/01/31/structured-finance-platform-intain-opens-tokenized-marketplace-for-asset-backed-securities/",

      image: news2,
      label:
        "Structured Finance Platform Intain Opens Tokenized Marketplace for Asset-Backed Securities.",
      link: "www.coindesk.com",
      date: "09/05/2023",
    },
  ];

  const EventsItem = [
    {
      image: Events1,
      span: "Powering Business with Blockchain: Avalanche & Intain",
      label:
        "Coming up on the next Powering Business with Blockchain, Intain joins to talk about IntainMARKETS, a new tokenized marketplace for asset-backed securities, being built on Avalanche.",
      link: "March 13, 2023",
      date: "13/02/2023",

      navigateTo: "/powering-business-with-blockchain-avalanche-intain", // Add a dynamic route here
    },
    {
      image: Events2,
      span: "Powering Business with Blockchain: Avalanche & Intain",
      label:
        "Structured Finance Platform Intain Opens Tokenized Marketplace for Asset-Backed Securities.",
      link: "www.coindesk.com",
      navigateTo: "/sfa-vegas-2023", // Add a dynamic route here
    },
  
  ];

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await mediumpost(2);
      if (res) {
        console.log("-:", res);
        setPost(res);
      } else {
        // setError("Failed to fetch Medium posts");
      }
    };

    fetchPosts(); // Call the async function
  }, []); // Empty dependency array ensures it runs once
  return (
    <Box className="green-page whatsnew">
      <div className="platform">
        <div>What’s new ?</div>
        <span></span>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Slider {...settings}>
          {post?.map((slide, index) => (
            <div key={index} className="whatsnew-container">
              <div className="whatsnewbox">
                <div className="imgbox">
                  <a
                    href={slide.mediumEndpoint || "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {slide.images && slide.images.length > 0 && (
                      <img src={slide.images[0]} alt={slide.title || "Image"} />
                    )}
                    <div>{slide.title}</div>
                  </a>
                </div>
              </div>
              <div className="whatsnewsubimg">
                <img src={whatsnew} alt="img" />
                <label>
                  {slide.published
                    ? new Date(slide.published).toLocaleDateString()
                    : "Not Provided"}
                </label>
              </div>
            </div>
          ))}
          {newsItems.map((item, index) => (
            <div key={index} className="whatsnew-container">
              <div className="whatsnewbox">
                <div className="imgbox">
                  <a
                    href={item.navigateTo || "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.image} alt="news" />
                    <div>{item.label}</div>
                  </a>

                  {/* <p>{item.link}</p> */}
                </div>
              </div>
              <div className="whatsnewsubimg">
                <img src={whatsnew} alt="img" />
                <label>{item.date}</label>
              </div>
            </div>
          ))}
           {EventsItem.map((item, index) => (
            <div key={index} className="whatsnew-container">
              <div className="whatsnewbox">
                <div className="imgbox">
                  <a
                    href={item.navigateTo || "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.image} alt="events" />
                    <div>{item.span}</div>
                  </a>

                  {/* <p>{item.link}</p> */}
                </div>
              </div>
              <div className="whatsnewsubimg">
                <img src={whatsnew} alt="img" />
                <label>{item.date}</label>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Box>
  );
};

export default WhatsNew;
