import React, { useState, useEffect } from "react";
import intainlogo from "../../img/intainlogo-2-1.png";
import intaindarklogo from "../../img/intaindarklogo.svg";
import linkedin from "../../img/LinkedIn.png";
import twitter from "../../img/TwitterX.png";
import iso from "../../img/iso-27001-1-1.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import likedindark from "../../img/linkedindark.svg";
import twitterdark from "../../img/twitterdark.svg";
import { emailSubscription } from "../../Actions/Action";
const footerData = [
  {
    title: "Products",
    buttons: [
      { label: "INTAIN MARKETS", path: "/intain-markets" },
      { label: "INTAIN ADMIN", path: "/intain-admin" },
      { label: "AI with IN-D", url: "https://in-d.ai/" },
    ],
  },
  {
    title: "Company",
    buttons: [
      { label: "ABOUT US", path: "/company" },
      { label: "NEWS & EVENTS", path: "/company?section=news-events" },
      { label: "CAREERS", path: "/company?section=careers" },
    ],
  },
  {
    title: "Resources",
    buttons: [
      { label: "INSIGHTS", path: "/resource?section=insights" },
      { label: "PRODUCT RELEASES", path: "/resource?section=products" },
      { label: "PRIVACY POLICY", path: "/privacy-policy" },
      { label: "TERMS & CONDITIONS", path: "/term-and-conditions" },
    ],
  },
];

const Footer = ({ footerInputRef }) => {
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );
  const [email, setEmail] = useState("");
  const [messageBox, setMessageBox] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [loader, setLoader] = useState(false);

  const getEmail = (e) => {
    setEmail(e.target.value);
  };

  const subscribeEmail = async () => {
    setLoader(true);
    const { success, message } = await emailSubscription(email);
    setLoader(false);
    setMessageBox(message);
    setIsSuccess(success);
    setEmail(""); // Clear the input field
    setTimeout(() => setMessageBox(""), 7000);
  };

  // working
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    // Directly listen for manual theme change events (if user toggles dark mode within same tab)
    window.addEventListener("storage", checkTheme);

    // Optional: If you have a theme toggle button elsewhere, you can listen to it
    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);
  return (
    <div className="green-page">
      <div className="footer">
        <div class="d-flex align-items-center gap-custom-footer">
          <div>
            <img
              src={isDarkMode ? intainlogo : intaindarklogo}
              className="footer-img"
              alt="img"
              onClick={() => navigate("/")}
            />
            <div className="pt-3 d-flex align-items-center gap-3">
              <a
                href="https://www.linkedin.com/company/intain/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={isDarkMode ? linkedin : likedindark}
                  alt="LinkedIn" // Always include alt text for accessibility
                  className="social-media-img"
                />
              </a>
              <a
                href="https://x.com/intainft?s=21"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={isDarkMode ? twitter : twitterdark}
                  className="social-media-img"
                  alt="img"
                />
              </a>
            </div>
          </div>
          <div className="footer-first">
            {footerData.map((section, index) => (
              <div
                className="footersection"
                key={index}
                style={
                  section.title === "Resources" ? { marginTop: "30px" } : {}
                }
              >
                <label>{section.title}</label>
                <p></p>
                {section.buttons.map((button, btnIndex) => (
                  <button
                    key={btnIndex}
                    onClick={() =>
                      button.url
                        ? window.open(button.url, "_blank")
                        : navigate(button.path)
                    }
                    style={{ marginTop: "20px" }}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="footer-second">
          <div className="startupdate">
            <label>Stay up to date</label>
            <p>
              Your email will be used to send you our new blog posts and
              updates. You can unsubscribe at any time using the link in our
              emails.
            </p>
          </div>

          <div className="email">
            <label>Email</label>
            <input
              ref={footerInputRef} // Attach the ref here
              onChange={(e) => {
                getEmail(e);
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                subscribeEmail();
              }}
            >
              {loader ? "Loading..." : "Subscribe"}
            </Button>{" "}
            {messageBox && (
              <div
                className="message-box"
                style={{
                  position: "fixed",
                  bottom: "40rem",
                  right: "10px",
                  backgroundColor: "white",
                  padding: "10px",
                  border: isSuccess ? "1px solid green" : "1px solid red",
                  color: isSuccess ? "green" : "red",
                  borderRadius: "5px",
                  textAlign: "center",
                  maxWidth: "320px",
                  maxHeight: "150px", // limit height
                  overflowY: "auto", // make content scrollable if it exceeds maxHeight
                  zIndex: 1000,
                }}
              >
                {messageBox}
              </div>
            )}
          </div>
        </div>
        <div className="footer-third">
          <div className="iso">
            <img src={iso} alt="img" />
          </div>
          <label>
            Intain has been certified by IAS to ISO
            <br /> 27001 under certificate number
            <br /> IAS/IND/9934
          </label>
          <p>
            © Copyright 2022 Intain Technologies.
            <br /> All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
